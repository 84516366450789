<template>
  <v-row>
    <v-col cols="8">
      <div class="text-caption mb-3">
        Merchant Commission Percent
      </div>
      <v-simple-table>
        <template #default>
          <thead>
            <tr scope="Row">
              <th class="text-left" scope="Col" />
              <th
                v-for="header in CLIENTS_COMMISSIONS_PERCENT_TABLE_HEADERS"
                :key="header.text"
                class="text-left sb-bg" scope="Col"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr scope="Row">
              <td>Merchant commission percent</td>
              <td>{{ commissionCharges.merchantCommission.previous.percentage }}%</td>
              <td>{{ commissionCharges.merchantCommission.current.percentage }}%</td>
              <td>{{ commissionCharges.merchantCommission.future.percentage }}%</td>
            </tr>
            <tr scope="Row">
              <td>Effective date</td>
              <td>{{ stringDateFormat(commissionCharges.merchantCommission.previous.effectiveDate) }}</td>
              <td>{{ stringDateFormat(commissionCharges.merchantCommission.current.effectiveDate) }}</td>
              <td>{{ stringDateFormat(commissionCharges.merchantCommission.future.effectiveDate) }}</td>
            </tr>
            <tr scope="Row">
              <td>Normal commission percent</td>
              <td>{{ commissionCharges.normalCommission.previous.percentage }}%</td>
              <td>{{ commissionCharges.normalCommission.current.percentage }}%</td>
              <td>{{ commissionCharges.normalCommission.future.percentage }}%</td>
            </tr>
            <tr scope="Row">
              <td>Effective date</td>
              <td>{{ stringDateFormat(commissionCharges.normalCommission.previous.effectiveDate) }}</td>
              <td>{{ stringDateFormat(commissionCharges.normalCommission.current.effectiveDate) }}</td>
              <td>{{ stringDateFormat(commissionCharges.normalCommission.future.effectiveDate) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { CLIENTS_COMMISSIONS_PERCENT_TABLE_HEADERS } from '@/config/constants';
import { formatDate, stringDateFormat } from '@/utils';

export default {
  props: {
    commissionPercent: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      CLIENTS_COMMISSIONS_PERCENT_TABLE_HEADERS,
      formatDate,
      stringDateFormat,
    };
  },
  computed: {
    ...mapGetters({
      commissionCharges: 'merchants/commissionCharges',
    }),
  },
  methods: {},
};
</script>
