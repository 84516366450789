<template>
  <sb-page-content :title="pageTitle"
                   class="back-button-margin">
    <v-row>
      <v-col>
        <sb-card title="Merchant Details"
                 min-width="100%">
          <v-card-text>
            <sb-merchant-search centered
                                :address-hidden="true"
                                :half-width="true"
                                :annual-turnover="annualTurnover"
                                :effective-date="effectiveDate"
                                @merchant-clear="clearMerchantDetails"
                                @merchant-update="refreshMerchantDetails" />

            <v-row v-if="$wait.is('commissionChargesLoading')"
                   justify="center"
                   class="mt-5 pt-5">
              <sb-loader />
            </v-row>

            <v-row v-if="hasMerchantCommission && !$wait.is('commissionChargesLoading')" class="px-2">
              <sb-merchant-commission-percent :commission-percent="merchantCommissionChargesObject.commissionPercent" />
            </v-row>
          </v-card-text>
        </sb-card>
      </v-col>
    </v-row>
    <v-row v-if="hasClientsCommissions">
      <v-col>
        <sb-card title="Fleet Client Details"
                 min-width="100%">
          <v-card-text>
            <v-row v-if="$wait.is('commissionChargesLoading')"
                   justify="center"
                   class="mx-5 pt-5">
              <sb-loader />
            </v-row>
            <v-row v-if="hasClientsCommissions && !$wait.is('commissionChargesLoading')" class="px-2">
              <sb-fleet-client-details :clients-commissions="clientsCommissions" />
            </v-row>
          </v-card-text>
        </sb-card>
      </v-col>
    </v-row>
  </sb-page-content>
</template>

<script>
import SbMerchantSearch from '@/components/merchant/SbMerchantSearch';
import SbMerchantCommissionPercent from '@/components/merchant/SbMerchantCommissionPercent';
import SbFleetClientDetails from '@/components/merchant/SbFleetClientDetails';
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

export default {
  components: {
    SbMerchantSearch,
    SbMerchantCommissionPercent,
    SbFleetClientDetails,
  },
  props: {},
  data() {
    return {
      search: {
        merchantId: '',
        isMerchant: false,
      },
      pageTitle: 'Summary of Commission Charges',
      commission: {
        foundPercentage: false,
        clients: [],
      },
      merchantCommissionChargesObject: {
        resultVisible: false,
      },
      annualTurnover: '',
      effectiveDate: '',
    };
  },
  computed: {

    commissionChargesLoading() {
      return this.$wait.is('commissionChargesLoading');
    },

    hasClientsCommissions() {
      return this.merchantCommissionChargesObject.clientCommission !== undefined && this.merchantCommissionChargesObject.clientCommission.length > 0;
    },

    clientsCommissions() {
      return this.merchantCommissionChargesObject.clientCommission;
    },

    hasMerchantCommission() {
      return !!this.merchantCommissionChargesObject.merchantCommission;
    },
  },
  watch: {
    merchantCommissionChargesObject: {
      handler(value) {
        this.commission.foundPercentage = true;
        this.merchantCommissionChargesObject.resultVisible = true;
        this.annualTurnover = this.merchantCommissionChargesObject.annualTurnover ? this.merchantCommissionChargesObject.annualTurnover : 0.0;
        this.effectiveDate = this.merchantCommissionChargesObject.effectiveDate ? this.merchantCommissionChargesObject.effectiveDate : '-';
        this.commission.clients = value.clientCommission;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('merchants', ['getMerchantCommissionCharges']),

    ...mapWaitingActions('merchants', {
      getMerchantCommissionCharges: 'commissionChargesLoading',
    }),

    async searchCommissionCharges(merchantId) {
      this.merchantCommissionChargesObject = await this.getMerchantCommissionCharges(merchantId);
    },

    refreshMerchantDetails(merchantDetails) {
      return merchantDetails && merchantDetails.merchant && merchantDetails.merchant.id ? this.searchCommissionCharges(merchantDetails.merchant.id)
        : null;
    },

    clearMerchantDetails() {
      this.search = {
        merchantId: '',
        isMerchant: false,
      };
      this.commission.foundPercentage = false;
      this.merchantCommissionChargesObject = {
        resultVisible: false,
      };
      this.annualTurnover = 0.0;
      this.effectiveDate = '-';
      this.commission.clients = [];
    },
  },
};
</script>
