<template>
  <v-simple-table>
    <template #default>
      <thead>
        <tr scope="row">
          <th v-for="(header, index) in CLIENTS_COMMISSIONS_TABLE_HEADERS"
              :key="index"
              class="text-left sb-bg"
              scope="col">
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(client, index) in clientsCommissions"
            :key="index"
            scope="row">
          <td>{{ client.fleetClientNumber }}</td>
          <td>{{ client.fleetClientName }}</td>
          <td>{{ client.jvSettlementDiscount.previous.percentage }}%</td>
          <td class="sb-nowrap">
            {{ stringDateFormat(client.jvSettlementDiscount.previous.effectiveDate) }}
          </td>
          <td>{{ client.jvSettlementDiscount.current.percentage }}%</td>
          <td class="sb-nowrap">
            {{ stringDateFormat(client.jvSettlementDiscount.current.effectiveDate) }}
          </td>
          <td>{{ client.jvSettlementDiscount.future.percentage }}%</td>
          <td class="sb-nowrap">
            {{ stringDateFormat(client.jvSettlementDiscount.future.effectiveDate) }}
          </td>
          <td class="sb-nowrap">
            {{ client.annualTurnover | currency }}
          </td>
          <td>{{ client.numberOfVehicles }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { CLIENTS_COMMISSIONS_TABLE_HEADERS } from '@/config/constants';
import { stringDateFormat } from '@/utils';

export default {
  props: {
    clientsCommissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      CLIENTS_COMMISSIONS_TABLE_HEADERS,
      stringDateFormat,
    };
  },
};
</script>
