<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <sb-input label="Merchant Number">
          <v-autocomplete v-model="selectedMerchantResult"
                          :loading="$wait.is('searchMerchantsLoading')"
                          :items="merchantResults"
                          :search-input.sync="searchMerchantString"
                          item-text="id"
                          return-object
                          cache-items
                          hide-details
                          hide-selected
                          dense
                          clearable
                          outlined
                          @click:clear="clearMerchantResult"
                          @input="updateMerchantResult">
            <template #no-data>
              <v-list-item>
                <v-list-item-title>
                  Search for Merchants by
                  <strong>Merchant Number</strong>
                </v-list-item-title>
              </v-list-item>
            </template>

            <template #selection="{item}">
              <span>{{ item.name }} ({{ item.id }})</span>
            </template>

            <template #item="{item}">
              <v-list-item-content>
                <v-list-item-title v-text="item.name" />
                <v-list-item-subtitle v-text="item.id" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </sb-input>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row v-if="$wait.is('getMerchantDetailsLoading')"
             justify="center"
             class="mt-5 pt-5">
        <sb-loader />
      </v-row>

      <v-row v-if="merchantObject.merchantResultVisible && !$wait.is('getMerchantDetailsLoading')"
             :justify="centered ? 'center' : undefined">
        <v-col cols="12">
          <div class="text-caption mb-3">
            Merchant Details
          </div>
          <v-row>
            <v-col :cols="halfWidth? '6': '12'"
                   :sm="halfWidth? '3': '6'">
              <sb-input label="Merchant Number"
                        :value="merchantObject.merchant.id" />
            </v-col>
            <v-col :cols="halfWidth? '6': '12'"
                   :sm="halfWidth? '3': '6'">
              <sb-input label="Merchant Name"
                        :value="merchantObject.merchant.name" />
            </v-col>
            <v-col v-if="(annualTurnover !== '')"
                   :cols="halfWidth? '6': '12'"
                   :sm="halfWidth? '3': '6'">
              <sb-input label="Merchant Life to Date Unaged Turnover"
                        :value="annualTurnoverCurrencyResult" />
            </v-col>
            <v-col v-if="(effectiveDate !== '')"
                   :cols="halfWidth? '6': '12'"
                   :sm="halfWidth? '3': '6'">
              <sb-input label="Effective Date"
                        :value="effectiveDate" />
            </v-col>
            <v-col v-if="!addressHidden"
                   cols="12">
              <sb-input label="Address"
                        :value="merchantObject.merchant.address" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

export default {
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    addressHidden: {
      type: Boolean,
      default: false,
    },
    halfWidth: {
      type: Boolean,
      default: false,
    },
    annualTurnover: {
      type: Number,
      default: 0.0,
    },
    effectiveDate: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      searchMerchantString: null,
      selectedMerchantResult: null,
      merchantObject: {
        merchantResultVisible: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      merchantResults: 'merchants/merchantResults',
    }),

    annualTurnoverCurrencyResult() {
      return this.$currency.format(this.annualTurnover);
    },
  },

  watch: {
    searchMerchantString(value) {
      // eslint-disable-next-line
      value && value !== this.selectedMerchantResult && this.searchMerchants(value);
    },
  },

  methods: {
    ...mapActions('vehicles', ['searchVehicles', 'getVehicleDetails']),
    ...mapActions('merchants', ['searchMerchants', 'getMerchantDetails']),

    ...mapWaitingActions('vehicles', {
      searchVehicles: 'searchVehiclesLoading',
      getVehicleDetails: 'getVehicleDetailsLoading',
    }),

    ...mapWaitingActions('merchants', {
      searchMerchants: 'searchMerchantsLoading',
      getMerchantDetails: 'getMerchantDetailsLoading',
    }),

    customSearchVehicleFilter(item, queryText) {
      const registrationNumber = item.registrationNumber ? item.registrationNumber.toLowerCase() : '';
      const vin = item.vehicleIdentificationNumber ? item.vehicleIdentificationNumber.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return registrationNumber.includes(searchText) || vin.includes(searchText);
    },

    async updateMerchantResult() {
      if (this.selectedMerchantResult) {
        this.merchantObject.merchant = await this.getMerchantDetails(this.selectedMerchantResult.id);

        this.merchantObject.merchantResultVisible = true;
      } else {
        this.merchantObject.merchantResultVisible = false;
      }

      this.$emit('merchant-update', this.merchantObject);
    },

    clearMerchantResult() {
      this.searchMerchantString = null;
      this.selectedMerchantResult = null;
      this.merchantObject.merchant = null;
      this.merchantObject.merchantResultVisible = false;
      this.$emit('merchant-clear', null);
    },
  },
};
</script>
